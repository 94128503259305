import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    fonts: {
        h1: 'HelveticaNeueLTStd-Th',
        h2: 'HelveticaNeueLTStd-Th',
        h4: 'HelveticaNeueLTStd-Th',
        default: 'HelveticaNeueLTStd-Lt',
        roman: 'HelveticaNeueLTStd-Roman',
    },
    fontSizes: {
        xl: '4.375rem',
        lg: '3.125rem',
        md: '1.875rem',
        sm: '1.125rem',
        default: '1rem',
    },
    colors: {
        fadedOrange: '#ef8e4e',
        burntSienna: '#c34e14',
        backgroundLight: 'rgba(255,102,0,0.05)',
        darkOverlay: 'rgba(33,33,33,0.60)',
        veryLightPink: '#fff7f1',
        pale: '#fbe3d3',
        white: '#FFFFFF',
        black: '#2e2e2e',
        darkGrey: '#212121',
        lightGrey: '#FAFAFA',
        grey: '#969796',
        impressionText: '#f8f9fa',
    },
    linkColors: {
        default: '#2e2e2e',
    },
    navLinks: {
        height: '2.375rem',
        fontFamily: 'HelveticaNeueLTStd-Lt',
        fontSize: '1.125rem',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2.11',
        letterSpacing: '0.125rem',
        color: '#2e2e2e',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    flexAlignEnd: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexColumn: {
        flexDirection: 'column',
    },
};

export { theme };