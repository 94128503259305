import React from 'react';
import PropTypes from 'prop-types';
import { BaseCSS, GridThemeProvider, media } from 'styled-bootstrap-grid';
import 'styled-components';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import 'keen-slider/keen-slider.min.css';
import '../static/fonts/fonts.css';

declare module 'styled-components' {
    export interface DefaultTheme {
        fonts: {
            h1: string;
            h2: string;
            h4: string;
            default: string;
            roman: string;
        };
        fontSizes: {
            xl: string;
            lg: string;
            md: string;
            sm: string;
            default: string;
        };
        colors: {
            fadedOrange: string;
            burntSienna: string;
            backgroundLight: string;
            darkOverlay: string;
            veryLightPink: string;
            pale: string;
            white: string;
            black: string;
            darkGrey: string;
            lightGrey: string;
            grey: string;
            impressionText: string;
        };
        linkColors: {
            default: string;
        };
        navLinks: {
            height: string;
            fontFamily: string;
            fontSize: string;
            letterSpacing: string;
            lineHeight: string;
            fontWeight: string;
            fontStretch: string;
            fontStyle: string;
            color: string;
        };
        flexCenter: {
            display: string;
            justifyContent: string;
            alignItems: string;
        };
        flexStart: {
            display: string;
            justifyContent: string;
            alignItems: string;
        };
        flexEnd: {
            display: string;
            justifyContent: string;
            alignItems: string;
        };
        flexAlignEnd: {
            display: string;
            justifyContent: string;
            alignItems: string;
        };
        flexBetween: {
            display: string;
            justifyContent: string;
            alignItems: string;
        };
        flexColumn: {
            flexDirection: string;
        };
    }
}

const gridTheme = {
    container: {
        padding: 70,
    },
    breakpoints: {
        xxl: 1440,
        xl: 1200,
        lg: 1366,
        md: 769,
        sm: 576,
        xs: 575,
    },
};

const GlobalStyle = createGlobalStyle`
    .keen-slider, .keen-slider__slide {
        overflow: unset;
    }
   
    *,
    *::before,
    *::after {
      -webkit-box-sizing: inherit;
              box-sizing: inherit;
    }
    
    html {
        scroll-behavior: smooth;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-overflow-style: scrollbar;
        font-size: ${(props) => props.theme.fontSizes.default};
        font-family: ${(props) => props.theme.fonts.default};
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }
    h1 {
        font-family: ${(props) => props.theme.fonts.h1};
        font-size: ${(props) => props.theme.fontSizes.xl};
        color: ${(props) => props.theme.colors.white};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.368rem;
    }
    h2 {
        font-size: ${(props) => props.theme.fontSizes.md};
        font-family: ${(props) => props.theme.fonts.h2};
        color: ${(props) => props.theme.colors.white};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.25rem;
        ${media.lg`
            font-size: ${(props) => props.theme.fontSizes.lg};
        `}
    }
    h4 {
        font-family: ${(props) => props.theme.fonts.h4};
        font-size: ${(props) => props.theme.fontSizes.sm};
        color: ${(props) => props.theme.colors.darkGrey};
        letter-spacing: 0.125rem;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    input[type=number] {
      -moz-appearance: textfield;
    }
    
    .gatsby-image-wrapper {
        width: 100%;
    }
    
    #gatsby-focus-wrapper {
        position: relative;
    }
`;

const PageWrapper = styled.section`
    max-width: 90rem;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    a {
        &:hover {
            text-decoration: line-through;
        }
    }
`;

const Layout = ({ backgroundColor = '', children }) => {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <BaseCSS />
                <GlobalStyle />
                <GridThemeProvider gridTheme={gridTheme}>
                    <PageWrapper>
                        <main style={{ backgroundColor: backgroundColor }}>{children}</main>
                    </PageWrapper>
                </GridThemeProvider>
            </ThemeProvider>
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
